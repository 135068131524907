// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/loader.gif");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/images/dark-loader.gif");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".modal-patient-overlay[data-v-6f2ef72e] {\n  position: fixed;\n  right: 0;\n  bottom: 0;\n  background-color: #ffecca;\n  z-index: 800;\n  overflow: hidden;\n}\n@media (min-width: 992px) {\n.modal-patient-overlay[data-v-6f2ef72e] {\n    z-index: 890;\n}\n}\n.loader-animation[data-v-6f2ef72e] {\n  background-color: #ffecca;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n.darkmode .loader-animation[data-v-6f2ef72e] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n.modal-patient-content[data-v-6f2ef72e] {\n  background-color: #fff;\n  overflow-y: auto;\n  overflow-x: hidden;\n}\n.darkmode .loader-animation[data-v-6f2ef72e],\n.darkmode .modal-patient-overlay[data-v-6f2ef72e],\n.darkmode .modal-patient-content[data-v-6f2ef72e] {\n  background-color: #121212;\n}\n.close-modal[data-v-6f2ef72e] {\n  cursor: pointer;\n  position: absolute;\n  top: 23px;\n  right: 15px;\n  z-index: 2;\n  width: 20px;\n  height: 20px;\n  background-size: cover;\n  background-repeat: no-repeat;\n  background-image: url(\"data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIiIGhlaWdodD0iMjIiIHZpZXdCb3g9IjAgMCAyMiAyMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTEiIGN5PSIxMSIgcj0iMTEiIGZpbGw9IiNGRjhENDAiLz4KPHBhdGggZD0iTTcuNzY0NDcgNy43NjQ3MUwxNC4yMzUxIDE0LjIzNTMiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMS44IiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPHBhdGggZD0iTTE0LjIzNDYgNy43NjQ1OUw3Ljc2NDAzIDE0LjIzNTIiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMS44IiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPC9zdmc+Cg==\");\n}\n@media (min-width: 768px) {\n.close-modal[data-v-6f2ef72e] {\n    top: 20px;\n    right: 30px;\n}\n}", ""]);
// Exports
module.exports = exports;
