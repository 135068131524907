<template>
  <div
    class="
      modal-patient-overlay
      tw-flex tw-flex-col tw-top-[80px] tw-left-0
      md:tw-flex-row
      xl:tw-top-0 xl:tw-left-[250px]
    "
  >
    <PatientInfo
      class="tw-flex-none"
      :patient-info="patientInfo"
      :show-notes.sync="showNotesModal"
      :show-pricing.sync="showPricingModal"
      v-if="!isPatientInfoEmpty"
      @show-dosespot="onShowDosespot"
      @switch-view="onSwitchView"
    />

    <div
      class="
        tw-h-[125px]
        tw-w-auto
        tw-flex
        tw-items-center
        tw-justify-center
        tw-bg-contain
        tw-bg-no-repeat
        tw-bg-center
        md:tw-h-screen md:tw-w-[101px]
        loader-animation
      "
      v-else
    ></div>
    <div
      class="
        tw-flex tw-relative tw-flex-grow
        modal-patient-content
        tw-overflow-hidden
      "
      v-if="patientInfo"
    >
      <slot
        :openNotesHandler="openNotesHandler"
        :currentView="currentView"
      ></slot>
      <template v-if="!hideClose">
        <span
          class="close-modal"
          @click="$router.push('/')"
          v-if="!isPatientInfoEmpty"
        ></span>
      </template>
    </div>

    <modal-set-reminder :patient="patientInfo"></modal-set-reminder>

    <portal to="notes">
      <ModalBodyNotes :show-notes-modal.sync="showNotesModal" />
    </portal>

    <portal to="pricing">
      <ModalPricingList
        :show-pricing-modal.sync="showPricingModal"
        :patientId="patientInfo.id"
        :pricingversion="patientInfo.pricingversion || 'v1'"
      />
    </portal>

    <b-modal
      id="viewDosespotFromLayout"
      centered
      hide-footer
      size="xl"
      v-if="['patientmessage'].includes($route.name)"
      v-model="isDosespotModalOpen"
    >
      <template v-slot:modal-header="{ close }">
        <b-container fluid></b-container>
        <!-- Emulate built in modal header close button action -->
        <b-button size="sm" variant="link" @click="close">
          <b-icon
            icon="x-circle-fill"
            variant="secondary"
            style="width: 30px; height: 30px"
          ></b-icon>
        </b-button>
      </template>
      <b-row no-gutters>
        <b-col cols="12">
          <template v-if="isFetchingDosespot && !this.dosespotURL">
            <b-row align-v="center" style="height: 350px">
              <b-col cols="12" class="text-center">
                <h1 class="text-primary">Loading Dosespot...</h1>
              </b-col>
            </b-row>
          </template>
          <iframe
            v-else
            :src="this.dosespotURL"
            width="100%"
            height="100%"
            frameborder="0"
            scrolling="yes"
            allowtransparency="true"
            :style="{ minHeight: iframeHeight + 'px' }"
          ></iframe>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import PatientInfo from "@/components/modal/ModalPatientInfo";
import ModalBodyNotes from "@/components/patients/ModalBodyNotes.vue";
import ModalSetReminder from "@/components/reminder/ModalSetReminder.vue";
import ModalPricingList from "@/components/patients/ModalPricingList.vue";

import { mapGetters } from "vuex";
import _ from "lodash";
export default {
  name: "PatientInfoLayout",
  components: {
    PatientInfo,
    ModalBodyNotes,
    ModalSetReminder,
    ModalPricingList,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    hideClose: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showNotesModal: false,
      showPricingModal: false,

      isFetchingDosespot: false,
      dosespotURL: "",
      iframeHeight: null,

      currentView: "prescription-history",
      isDosespotModalOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      patientInfo: "patients/getPatientInfo",
    }),
    isPatientInfoEmpty() {
      return _.isEmpty(this.patientInfo);
    },
    currentDoctor() {
      return this.$store.state.globals.currentDoctor;
    },
  },
  watch: {
    isDosespotModalOpen(val) {
      if (
        val &&
        !this.isPatientInfoEmpty &&
        ["patientmessage"].includes(this.$route.name) &&
        !this.currentDoctor.id
      ) {
        this.fetchDosespotURL();
      }
    },
  },
  mounted() {
    this.matchModalHeight();

    window.addEventListener("resize", () => {
      this.matchModalHeight();
    });
  },
  methods: {
    openNotesHandler() {
      this.showNotesModal = true;
    },
    matchModalHeight() {
      let appHeight = window.innerHeight - 18;
      this.iframeHeight = appHeight;
    },
    async fetchDosespotURL() {
      try {
        this.isFetchingDosespot = true;
        const dosespotURL = await this.$store.dispatch(
          "prescription/generateDospatURL",
          this.patientInfo.id
        );
        this.dosespotURL = dosespotURL;
      } catch (e) {
        console.error(e);
        throw e;
      } finally {
        this.isFetchingDosespot = false;
      }
    },
    onShowDosespot() {
      this.$bvModal.show("viewDosespotFromLayout");
    },
    onSwitchView(view) {
      this.currentView = view;
    },
  },
};
</script>

<style scoped>
/deep/#viewDosespotFromLayout .modal-dialog {
  min-width: 100% !important;
  min-height: 100% !important;
  margin: 0 !important;
}

/deep/#viewDosespotFromLayout .modal-body {
  padding: 0;
}

/deep/#viewDosespotFromLayout .modal-header {
  border: none;
}
</style>

<style lang="scss" scoped>
.modal-patient-overlay {
  position: fixed;
  right: 0;
  bottom: 0;
  background-color: #ffecca;
  z-index: 800;
  overflow: hidden;

  @include media-breakpoint-up(lg) {
    z-index: 890;
  }
}

.loader-animation {
  background-color: #ffecca;
  background-image: url("~@/assets/images/loader.gif");
}

.darkmode .loader-animation {
  background-image: url("~@/assets/images/dark-loader.gif");
}

.modal-patient-content {
  background-color: #fff;
  overflow-y: auto;
  overflow-x: hidden;
}

.darkmode .loader-animation,
.darkmode .modal-patient-overlay,
.darkmode .modal-patient-content {
  background-color: #121212;
}

.close-modal {
  cursor: pointer;
  position: absolute;
  top: 23px;
  right: 15px;
  z-index: 2;
  width: 20px;
  height: 20px;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIiIGhlaWdodD0iMjIiIHZpZXdCb3g9IjAgMCAyMiAyMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTEiIGN5PSIxMSIgcj0iMTEiIGZpbGw9IiNGRjhENDAiLz4KPHBhdGggZD0iTTcuNzY0NDcgNy43NjQ3MUwxNC4yMzUxIDE0LjIzNTMiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMS44IiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPHBhdGggZD0iTTE0LjIzNDYgNy43NjQ1OUw3Ljc2NDAzIDE0LjIzNTIiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMS44IiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPC9zdmc+Cg==");

  @include media-breakpoint-up(md) {
    top: 20px;
    right: 30px;
  }
}
</style>
